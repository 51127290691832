<template>
	<div>
		<div class="margin_top_20 steps kxsteps rzkxsteps">
			<el-steps direction="vertical" :active="1">
				<el-step v-for="(item, index) in dataList" :key="index">
					<template slot="description">
						<router-link
							target="_blank"
							:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
						>
							<div class="pointer">
								<div class="stepsTime" v-if="item.release_time != null">
									{{ item.release_time }}
								</div>
								<div
									class="stepsVal ellipsis_one hovercolor"
									style="font-weight: bold"
									>{{ item.title }}</div
								>
								<div class="flex justify_content_sb align_items_c kxstepsBox">
									<div
										class="flex justify_content_sb align_items_c kxstepsBoxLeft"
									>
										<div class="flex align_items_c btnitem">
											<img
												src="https://res.metaera.hk/resources/assets/images/home/685.png"
												alt=""
											/>
											<div>分享</div>
										</div>
									</div>
								</div>
							</div>
						</router-link>
					</template>
				</el-step>
			</el-steps>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: ["dataList"],
};
</script>

<style>
.MainRight_3_title {
	width: 600px;
}
.kxsteps .stepsVal {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 15px;
}

.kxsteps .el-step__main {
	height: 118px;
}

.kxsteps .el-step__description {
	padding-right: 10% !important;
}

.kxsteps .btnitem div {
	font-size: 12px;
	color: #a0a0a8;
}

.chanyeTitle .rsTitle {
	margin-top: 0 !important;
}
.kxstepsBox {
	margin-top: 15px;
}

.kxstepsBoxLeft {
	width: 50%;
}

.kxstepsBox a {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}
.stepsTime {
	margin-top: -6px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600 !important;
	line-height: 1.5;
	color: #333333;
}
</style>

<template>
	<div v-if="version == 2" class="version2 flex direction_column">
		<div class="">
			<div class="active">注册账号</div>
			<div class="js-login">
				<div class="js-login__form">
					<p class="js-login__phone flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/my/email_tu.png"
							class="pointer"
						/>
						<input
							type="text"
							placeholder="请输入邮箱"
							v-model="loginForm.email"
						/>
					</p>
					<div class="margin_top_20 flex login_psd_box">
						<p class="js-login__psd flex-shrink">
							<img
								src="https://res.metaera.hk/resources/assets/images/my/yzm_tu.svg"
								class="pointer"
							/>
							<input
								placeholder="请输入验证码"
								type="text"
								v-model="loginForm.code"
							/>
						</p>
						<p class="login_psd_btn flex pointer">
							<span class="login_psd_text" @click="tosendSms" v-show="show"
								>获取验证码</span
							>
							<span class="login_psd_count" v-show="!show">{{ count }} s</span>
						</p>
					</div>
					<p class="js-login__psd margin_top_20">
						<img
							src="https://res.metaera.hk/resources/assets/images/my/pass_tu.png"
							class="pointer"
						/>
						<input
							placeholder="请设置密码"
							type="password"
							v-model="loginForm.password"
						/>
					</p>
					<p class="js-login__psd margin_top_20">
						<img
							src="https://res.metaera.hk/resources/assets/images/my/pass_tu.png"
							class="pointer"
						/>
						<input
							placeholder="请确认密码"
							type="password"
							v-model="new_password2"
						/>
					</p>
					<div class="js-login__agreement pointer margin_top_20">
						<p>
							<input type="checkbox" v-model="agree1" class="pointer" />
							<span class="js-login__agreement__text">
								我已阅读并接受
								<i
									class="js-login__agreement__text"
									@click="open(config.term_of_service.value)"
								>
									《MetaEra平台服务协议》
								</i>
							</span>
						</p>
						<p>
							<input type="checkbox" v-model="agree2" class="pointer" />
							<span class="js-login__agreement__text">
								我已阅读并接受
								<i
									class="js-login__agreement__text"
									@click="open(config.privacy_policy.value)"
								>
									《MetaEra平台隐私政策》
								</i>
							</span>
						</p>
					</div>
					<button
						class="loginBtn flex align_items_c justify_content_c pointer"
						@click="register"
						:disabled="
							loginForm.email == '' ||
							loginForm.code == '' ||
							loginForm.password == '' ||
							new_password2 == '' ||
							!agree1 ||
							!agree2
						"
					>
						注册
					</button>
				</div>
				<div class="return_btn flex justify_content_c margin_top_20">
					<p class="pointer" @click="$parent.$parent.showStatus = 1"
						>返回登录<img
							src="https://res.metaera.hk/resources/assets/images/my/arrow_tu.svg"
					/></p>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="showXy" append-to-body>
			<iframe
				v-bind:src="xyval"
				width="100%"
				height="550"
				scrolling="yes"
				frameborder="0"
			></iframe>
		</el-dialog>
	</div>
</template>

<script>
import store from "@/store";
import { sendSms, sendEmailSms, getAppConfig } from "@/api/home";
import "./login_common.css";
export default {
	name: "",
	props: ["version"],
	data() {
		return {
			showXy: false,
			xyval: "",
			loginForm: {
				username: "",
				code: "",
				type: "2",
				password: "",
				phone_code: "86",
				email: "",
				code: "",
			},
			agree1: false,
			agree2: false,
			new_password2: "",
			passType: "password",
			showtimezone: false,
			// 验证码
			show: true,
			timer: null,
			count: "",
			config: {},
			localLanguage: 0,
		};
	},
	created() {
		this.getAppConfig();
	},
	mounted() {},
	methods: {
		getAppConfig() {
			getAppConfig({})
				.then((res) => {
					if (res.code == 200) {
						this.config = res.data;
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		getphone_code(val) {
			this.loginForm.phone_code = val;
			this.showtimezone = false;
		},
		tosendSms() {
			sendEmailSms({
				email: this.loginForm.email,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					console.log(error);
					this.$message.error(error.message);
				});
		},
		register() {
			if (this.loginForm.password != this.new_password2) {
				this.$message.error("两次密码输入不一致");
			} else {
				console.log(this.$parent.$parent.showStatus);
				var loginInfo = {};
				if (this.$parent.$parent.showStatus == 2) {
					loginInfo = {
						email: this.loginForm.email,
						code: this.loginForm.code,
						password: this.loginForm.password,
						type: "5",
						username: "",
					};
					// 邮箱密码登录
				}
				store
					.dispatch("user/login", loginInfo)
					.then(() => {
						this.$parent.$parent.dialogVisible = false;
						this.$PromptTip({ dialogVisible: true, showStatus: 1 });
					})
					.catch((error) => {
						this.$message.error(error.message);
					});
			}
		},
		open(url) {
			this.xyval = url;
			this.showXy = true;
		},
	},
};
</script>

<style scoped>
/* 登录注册 */
.loginTab {
	margin-top: 14px;
}

.loginTab div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.loginTab div:nth-child(2) {
	margin-left: 47px;
}

.active {
	position: relative;
	font-weight: bold;
}

.loginMain {
	margin-top: 50px;
	width: 344px;
}

.login_form {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #e1e1e1;
}

.numberarea {
	font-size: 17px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #666666;
	width: 78px;
}

.login_form input {
	width: 60%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}

.login_form img {
	width: 14px;
}

.login_bt {
	margin-top: 15px;
}

.login_bt div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.loginBtn {
	width: 345px;
	height: 44px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	margin-top: 23px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	border: none;
	outline: none;
}

.loginBtn[disabled] {
	background: #8cb3ff;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0px;
}

.otherLogin {
	margin-top: 37px;
}

.otherLogin .el-divider__text {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wxlogin {
	width: 40px;
	margin-top: 28px;
}
</style>

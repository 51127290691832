<template>
	<el-dialog :visible.sync="dialogVisible" width="100%" height="100%">
		<div class="loginWraper flex align_items_c version2Wrap">
			<div class="loginBox_r_box flex align_items_c justify_content_c">
				<div class="box-width">
					<template v-if="showStatus == 1">
						<login_up></login_up>
						<login :version="version"></login>
					</template>
					<template v-if="showStatus == 2">
						<register :version="version"></register>
					</template>
					<template v-if="showStatus == 3">
						<login_up></login_up>
						<login_yzm :version="version"></login_yzm>
					</template>
					<template v-if="showStatus == 4">
						<forgetPass :version="version"></forgetPass>
					</template>
					<template v-if="showStatus == 5">
						<login_upStep :version="version"></login_upStep>
					</template>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import login from "../../components/login/login.vue";
import register from "../../components/login/register.vue";
import login_yzm from "../../components/login/login_yzm.vue";
import forgetPass from "../../components/login/forgetPass.vue";
import login_upStep from "../../components/login/login_upStep.vue";
import login_up from "../../components/login/login_up.vue";
export default {
	components: {
		login,
		register,
		login_yzm,
		forgetPass,
		login_upStep,
		login_up,
	},
	name: "",
	data() {
		return {
			version: 2,
			dialogVisible: true,
			showStatus: 5,
		};
	},
};
</script>

<style scoped>
.loginWraper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.8);
	/* background-image: url("https://res.metaera.hk/resources/assets/images/my/loginBg.png");
	background-size: cover;
	background-position: center; */
	padding: 0 99px 0 248px;
	justify-content: space-between;
}
.version2Wrap {
	.loginBox_r_box {
		flex: 2 2 auto;
		width: 420px;
		margin-top: 0;

		.box-width {
			width: 100%;
			max-width: 420px;
		}
	}

	.logo-tu {
		width: 295px;
		margin-right: 30px;
	}

	.flex_grow {
		flex-grow: 1;
	}

	.loginBox_l_box {
		width: 670px;
		flex: 3 3 auto;
		/*text-align: center;*/
		/*padding-right: 376px;*/
		/*margin-right: 378px;*/
	}
}
</style>

<style>
.el-dialog__body {
	padding: 0px;
}
.el-dialog__header {
	display: none;
}
/* 媒体查询 */
@media screen and (max-width: 900px) {
	.loginWraper {
		padding: 0 !important;
	}

	.loginBox_l_box {
		display: none;
	}

	.loginBox_r_box {
		width: 100%;
		padding: 10px;
	}
}
</style>

<template>
	<div class="version2 up_step_wraper">
		<div class="top-part flex padding_top_20 margin_left_20">
			<img
				class="pointer"
				@click="$parent.$parent.showStatus = 1"
				src="https://res.metaera.hk/resources/assets/images/my/arrow_left.svg"
			/>
			<div>
				<p class="main-title">账号升级</p>
				<p class="sub-title">将手机号登录升级为邮箱登录</p>
			</div>
		</div>
		<div class="icon-box flex">
			<template v-for="(item, index) in stepData">
				<div
					class="step-box"
					:class="{
						done: index + 1 < currentStep,
						active: index + 1 == currentStep,
						next: index + 1 > currentStep,
					}"
				>
					<div v-if="index + 1 < currentStep" class="step-icon">
						<img
							src="https://res.metaera.hk/resources/assets/images/my/right_tu.svg"
						/>
					</div>
					<div class="step-num" v-else>{{ index + 1 }}</div>
					<div class="step-text">{{ item }}</div>
				</div>
				<div
					v-if="index < stepData.length - 1"
					class="step-line flex align_items_c"
				>
					<span></span>
				</div>
			</template>
		</div>
		<div class="up-info-box">
			<div class="js-login">
				<div class="js-login__form">
					<!--   密码登录     -->
					<template v-if="currentStep == 1 && loginType == 1">
						<p class="js-login__phone flex align_items_c">
							<img
								src="https://res.metaera.hk/resources/assets/images/my/phone_tu.svg"
								class="pointer"
							/>
							<input
								type="text"
								placeholder="请输入账号"
								v-model="loginForm.username"
							/>
						</p>
						<p class="js-login__psd margin_top_20">
							<img
								src="https://res.metaera.hk/resources/assets/images/my/pass_tu.png"
								class="pointer"
							/>
							<input
								placeholder="请输入密码"
								type="password"
								v-model="loginForm.password"
							/>
						</p>
						<p class="js-login__info">
							<span
								class="js-login__changePhone pointer"
								@click="changeLoginType(2)"
							>
								验证码登录</span
							>
							<a href="mailto:meta@metaera.hk">
								<span class="js-login__forget pointer">忘记账号？点击申诉</span>
							</a>
						</p>
					</template>
					<template v-if="currentStep == 1 && loginType == 2">
						<p class="js-login__phone flex align_items_c">
							<img
								src="https://res.metaera.hk/resources/assets/images/my/phone_tu.svg"
								class="pointer"
							/>
							<el-dropdown
								class="login__phone_code_list"
								@command="handleCommand"
							>
								<span class="el-dropdown-link login__phone_code">
									+ {{ loginForm.phone_code }}
									<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item
										v-for="(item, key) in areaList"
										:key="key"
										:command="key"
										>+ {{ item.area_code }}</el-dropdown-item
									>
								</el-dropdown-menu>
							</el-dropdown>
							<input
								type="text"
								placeholder="请输入手机号"
								v-model="loginForm.username"
							/>
						</p>
						<div class="margin_top_20 flex login_psd_box">
							<p class="js-login__psd flex-shrink">
								<img
									src="https://res.metaera.hk/resources/assets/images/my/yzm_tu.svg"
									class="pointer"
								/>
								<input
									placeholder="请输入验证码"
									type="text"
									v-model="loginForm.code"
								/>
							</p>
							<p class="login_psd_btn flex pointer">
								<span class="login_psd_text" @click="tosendSms" v-show="show">{{
									btnText
								}}</span>
								<span class="login_psd_count" v-show="!show"
									>{{ count }} s</span
								>
							</p>
						</div>
						<p class="js-login__info">
							<span
								class="js-login__changePhone pointer"
								@click="changeLoginType(1)"
							>
								密码登录</span
							>
						</p>
					</template>
					<template v-if="currentStep == 2">
						<p class="js-login__phone flex align_items_c">
							<img
								src="https://res.metaera.hk/resources/assets/images/my/email_tu.png"
								class="pointer"
							/>
							<input
								type="text"
								placeholder="请输入邮箱"
								v-model="loginForm.email"
							/>
						</p>
						<div class="margin_top_20 flex login_psd_box">
							<p class="js-login__psd flex-shrink">
								<img
									src="https://res.metaera.hk/resources/assets/images/my/yzm_tu.svg"
									class="pointer"
								/>
								<input
									placeholder="请输入验证码"
									type="text"
									v-model="loginForm.emailCode"
								/>
							</p>
							<p class="login_psd_btn flex pointer">
								<span
									class="login_psd_text"
									@click="toSendMailSms"
									v-show="show"
									>{{ btnText }}</span
								>
								<span class="login_psd_count" v-show="!show"
									>{{ count }} s</span
								>
							</p>
						</div>
					</template>
					<template v-if="currentStep == 3">
						<p class="js-login__psd margin_top_20">
							<img
								src="https://res.metaera.hk/resources/assets/images/my/pass_tu.png"
								class="pointer"
							/>
							<input
								placeholder="请设置密码"
								type="password"
								v-model="loginForm.new_password1"
							/>
						</p>
						<p class="js-login__psd margin_top_20">
							<img
								src="https://res.metaera.hk/resources/assets/images/my/pass_tu.png"
								class="pointer"
							/>
							<input
								placeholder="请确认密码"
								type="password"
								v-model="loginForm.new_password2"
							/>
						</p>
					</template>
					<button
						v-if="currentStep == 3"
						@click="changeToLast"
						class="lastStep flex align_items_c justify_content_c pointer"
					>
						上一步
					</button>
					<button
						@click="handleLogin"
						class="loginBtn flex align_items_c justify_content_c pointer"
						:class="{
							loginBtn3: currentStep == 3,
							loginBtn2: currentStep == 2,
						}"
					>
						{{ currentStep == 3 ? "完成" : "下一步" }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import "./login_common.css";
import { sendSms, getAppConfig, sendEmailSms } from "@/api/home";
import { login, verifyEmailSms, resetPassword, editUserInfo } from "@/api/user";
import {
	getToken,
	setToken,
	removeToken,
	setUserId,
	setUserMobile,
	removeUserMobile,
	removeUserId,
} from "@/utils/auth";
export default {
	data() {
		return {
			loginForm: {
				username: "",
				code: "",
				new_password1: "",
				new_password2: "",
				phone_code: "86",
				email: "",
				emailCode: "",
			},
			passType: "password",
			currentStep: 1,
			allSteps: 3,
			loginType: 1, // 1 是密码登录，2是验证码登录
			stepData: ["手机号验证", "绑定邮箱", "设置密码"],
			localLanguage: 0,
			btnText: "获取验证码",
			show: true,
			timer: null,
			count: "",
			userId: "",
			areaList: [
				{
					id: 1,
					country: "中国",
					area_code: 86,
					continent: "亚洲",
				},
				{
					id: 2,
					country: "香港",
					area_code: 852,
					continent: "亚洲",
				},
				{
					id: 3,
					country: "澳门",
					area_code: 853,
					continent: "亚洲",
				},
				{
					id: 4,
					country: "台湾",
					area_code: 886,
					continent: "亚洲",
				},
				{
					id: 5,
					country: "马来西亚",
					area_code: 60,
					continent: "亚洲",
				},
				{
					id: 6,
					country: "印度尼西亚",
					area_code: 62,
					continent: "亚洲",
				},
				{
					id: 7,
					country: "菲律宾",
					area_code: 63,
					continent: "亚洲",
				},
				{
					id: 8,
					country: "新加坡",
					area_code: 65,
					continent: "亚洲",
				},
				{
					id: 9,
					country: "泰国",
					area_code: 66,
					continent: "亚洲",
				},
				{
					id: 10,
					country: "日本",
					area_code: 81,
					continent: "亚洲",
				},
				{
					id: 11,
					country: "韩国",
					area_code: 82,
					continent: "亚洲",
				},
				{
					id: 12,
					country: "塔吉克斯坦",
					area_code: 7,
					continent: "亚洲",
				},
				{
					id: 13,
					country: "哈萨克斯坦",
					area_code: 7,
					continent: "亚洲",
				},
				{
					id: 14,
					country: "越南",
					area_code: 84,
					continent: "亚洲",
				},
				{
					id: 15,
					country: "土耳其",
					area_code: 90,
					continent: "亚洲",
				},
				{
					id: 16,
					country: "印度",
					area_code: 91,
					continent: "亚洲",
				},
				{
					id: 17,
					country: "巴基斯坦",
					area_code: 92,
					continent: "亚洲",
				},
				{
					id: 18,
					country: "阿富汗",
					area_code: 93,
					continent: "亚洲",
				},
				{
					id: 19,
					country: "斯里兰卡",
					area_code: 94,
					continent: "亚洲",
				},
				{
					id: 20,
					country: "缅甸",
					area_code: 95,
					continent: "亚洲",
				},
				{
					id: 21,
					country: "伊朗",
					area_code: 98,
					continent: "亚洲",
				},
				{
					id: 22,
					country: "亚美尼亚",
					area_code: 374,
					continent: "亚洲",
				},
				{
					id: 23,
					country: "东帝汶",
					area_code: 670,
					continent: "亚洲",
				},
				{
					id: 24,
					country: "文莱",
					area_code: 673,
					continent: "亚洲",
				},
				{
					id: 25,
					country: "朝鲜",
					area_code: 850,
					continent: "亚洲",
				},
				{
					id: 26,
					country: "柬埔寨",
					area_code: 855,
					continent: "亚洲",
				},
				{
					id: 27,
					country: "老挝",
					area_code: 856,
					continent: "亚洲",
				},
				{
					id: 28,
					country: "孟加拉国",
					area_code: 880,
					continent: "亚洲",
				},
				{
					id: 29,
					country: "马尔代夫",
					area_code: 960,
					continent: "亚洲",
				},
				{
					id: 30,
					country: "黎巴嫩",
					area_code: 961,
					continent: "亚洲",
				},
				{
					id: 31,
					country: "约旦",
					area_code: 962,
					continent: "亚洲",
				},
				{
					id: 32,
					country: "叙利亚",
					area_code: 963,
					continent: "亚洲",
				},
				{
					id: 33,
					country: "伊拉克",
					area_code: 964,
					continent: "亚洲",
				},
				{
					id: 34,
					country: "科威特",
					area_code: 965,
					continent: "亚洲",
				},
				{
					id: 35,
					country: "沙特阿拉伯",
					area_code: 966,
					continent: "亚洲",
				},
				{
					id: 36,
					country: "也门",
					area_code: 967,
					continent: "亚洲",
				},
				{
					id: 37,
					country: "阿曼",
					area_code: 968,
					continent: "亚洲",
				},
				{
					id: 38,
					country: "巴勒斯坦",
					area_code: 970,
					continent: "亚洲",
				},
				{
					id: 39,
					country: "阿联酋",
					area_code: 971,
					continent: "亚洲",
				},
				{
					id: 40,
					country: "以色列",
					area_code: 972,
					continent: "亚洲",
				},
				{
					id: 41,
					country: "巴林",
					area_code: 973,
					continent: "亚洲",
				},
				{
					id: 42,
					country: "卡塔尔",
					area_code: 974,
					continent: "亚洲",
				},
				{
					id: 43,
					country: "不丹",
					area_code: 975,
					continent: "亚洲",
				},
				{
					id: 44,
					country: "蒙古",
					area_code: 976,
					continent: "亚洲",
				},
				{
					id: 45,
					country: "尼泊尔",
					area_code: 977,
					continent: "亚洲",
				},
				{
					id: 46,
					country: "土库曼斯坦",
					area_code: 993,
					continent: "亚洲",
				},
				{
					id: 47,
					country: "阿塞拜疆",
					area_code: 994,
					continent: "亚洲",
				},
				{
					id: 48,
					country: "乔治亚",
					area_code: 995,
					continent: "亚洲",
				},
				{
					id: 49,
					country: "吉尔吉斯斯坦",
					area_code: 996,
					continent: "亚洲",
				},
				{
					id: 50,
					country: "乌兹别克斯坦",
					area_code: 998,
					continent: "亚洲",
				},
				{
					id: 51,
					country: "英国",
					area_code: 44,
					continent: "欧洲",
				},
				{
					id: 52,
					country: "德国",
					area_code: 49,
					continent: "欧洲",
				},
				{
					id: 53,
					country: "意大利",
					area_code: 39,
					continent: "欧洲",
				},
				{
					id: 54,
					country: "法国",
					area_code: 33,
					continent: "欧洲",
				},
				{
					id: 55,
					country: "俄罗斯",
					area_code: 7,
					continent: "欧洲",
				},
				{
					id: 56,
					country: "希腊",
					area_code: 30,
					continent: "欧洲",
				},
				{
					id: 57,
					country: "荷兰",
					area_code: 31,
					continent: "欧洲",
				},
				{
					id: 58,
					country: "比利时",
					area_code: 32,
					continent: "欧洲",
				},
				{
					id: 59,
					country: "西班牙",
					area_code: 34,
					continent: "欧洲",
				},
				{
					id: 60,
					country: "匈牙利",
					area_code: 36,
					continent: "欧洲",
				},
				{
					id: 61,
					country: "罗马尼亚",
					area_code: 40,
					continent: "欧洲",
				},
				{
					id: 62,
					country: "瑞士",
					area_code: 41,
					continent: "欧洲",
				},
				{
					id: 63,
					country: "奥地利",
					area_code: 43,
					continent: "欧洲",
				},
				{
					id: 64,
					country: "丹麦",
					area_code: 45,
					continent: "欧洲",
				},
				{
					id: 65,
					country: "瑞典",
					area_code: 46,
					continent: "欧洲",
				},
				{
					id: 66,
					country: "挪威",
					area_code: 47,
					continent: "欧洲",
				},
				{
					id: 67,
					country: "波兰",
					area_code: 48,
					continent: "欧洲",
				},
				{
					id: 68,
					country: "圣马力诺",
					area_code: 223,
					continent: "欧洲",
				},
				{
					id: 69,
					country: "匈牙利",
					area_code: 336,
					continent: "欧洲",
				},
				{
					id: 70,
					country: "南斯拉夫",
					area_code: 338,
					continent: "欧洲",
				},
				{
					id: 71,
					country: "直布罗陀",
					area_code: 350,
					continent: "欧洲",
				},
				{
					id: 72,
					country: "葡萄牙",
					area_code: 351,
					continent: "欧洲",
				},
				{
					id: 73,
					country: "卢森堡",
					area_code: 352,
					continent: "欧洲",
				},
				{
					id: 74,
					country: "爱尔兰",
					area_code: 353,
					continent: "欧洲",
				},
				{
					id: 75,
					country: "冰岛",
					area_code: 354,
					continent: "欧洲",
				},
				{
					id: 76,
					country: "阿尔巴尼亚",
					area_code: 355,
					continent: "欧洲",
				},
				{
					id: 77,
					country: "马耳他",
					area_code: 356,
					continent: "欧洲",
				},
				{
					id: 78,
					country: "塞浦路斯",
					area_code: 357,
					continent: "欧洲",
				},
				{
					id: 79,
					country: "芬兰",
					area_code: 358,
					continent: "欧洲",
				},
				{
					id: 80,
					country: "保加利亚",
					area_code: 359,
					continent: "欧洲",
				},
				{
					id: 81,
					country: "立陶宛",
					area_code: 370,
					continent: "欧洲",
				},
				{
					id: 82,
					country: "拉脱维亚",
					area_code: 371,
					continent: "欧洲",
				},
				{
					id: 83,
					country: "爱沙尼亚",
					area_code: 372,
					continent: "欧洲",
				},
				{
					id: 84,
					country: "摩尔多瓦",
					area_code: 373,
					continent: "欧洲",
				},
				{
					id: 85,
					country: "安道尔共和国",
					area_code: 376,
					continent: "欧洲",
				},
				{
					id: 86,
					country: "乌克兰",
					area_code: 380,
					continent: "欧洲",
				},
				{
					id: 87,
					country: "南斯拉夫",
					area_code: 381,
					continent: "欧洲",
				},
				{
					id: 88,
					country: "克罗地亚",
					area_code: 385,
					continent: "欧洲",
				},
				{
					id: 89,
					country: "斯洛文尼亚",
					area_code: 386,
					continent: "欧洲",
				},
				{
					id: 90,
					country: "波黑",
					area_code: 387,
					continent: "欧洲",
				},
				{
					id: 91,
					country: "马其顿",
					area_code: 389,
					continent: "欧洲",
				},
				{
					id: 92,
					country: "梵蒂冈",
					area_code: 396,
					continent: "欧洲",
				},
				{
					id: 93,
					country: "捷克",
					area_code: 420,
					continent: "欧洲",
				},
				{
					id: 94,
					country: "斯洛伐克",
					area_code: 421,
					continent: "欧洲",
				},
				{
					id: 95,
					country: "列支敦士登",
					area_code: 423,
					continent: "欧洲",
				},
				{
					id: 96,
					country: "秘鲁",
					area_code: 51,
					continent: "南美洲",
				},
				{
					id: 97,
					country: "墨西哥",
					area_code: 52,
					continent: "南美洲",
				},
				{
					id: 98,
					country: "古巴",
					area_code: 53,
					continent: "南美洲",
				},
				{
					id: 99,
					country: "阿根廷",
					area_code: 54,
					continent: "南美洲",
				},
				{
					id: 100,
					country: "巴西",
					area_code: 55,
					continent: "南美洲",
				},
				{
					id: 101,
					country: "智利",
					area_code: 56,
					continent: "南美洲",
				},
				{
					id: 102,
					country: "哥伦比亚",
					area_code: 57,
					continent: "南美洲",
				},
				{
					id: 103,
					country: "委内瑞拉",
					area_code: 58,
					continent: "南美洲",
				},
				{
					id: 104,
					country: "福克兰群岛",
					area_code: 500,
					continent: "南美洲",
				},
				{
					id: 105,
					country: "伯利兹",
					area_code: 501,
					continent: "南美洲",
				},
				{
					id: 106,
					country: "危地马拉",
					area_code: 502,
					continent: "南美洲",
				},
				{
					id: 107,
					country: "萨尔瓦多",
					area_code: 503,
					continent: "南美洲",
				},
				{
					id: 108,
					country: "洪都拉斯",
					area_code: 504,
					continent: "南美洲",
				},
				{
					id: 109,
					country: "尼加拉瓜",
					area_code: 505,
					continent: "南美洲",
				},
				{
					id: 110,
					country: "哥斯达黎加",
					area_code: 506,
					continent: "南美洲",
				},
				{
					id: 111,
					country: "巴拿马",
					area_code: 507,
					continent: "南美洲",
				},
				{
					id: 112,
					country: "圣彼埃尔",
					area_code: 508,
					continent: "南美洲",
				},
				{
					id: 113,
					country: "海地",
					area_code: 509,
					continent: "南美洲",
				},
				{
					id: 114,
					country: "瓜德罗普",
					area_code: 590,
					continent: "南美洲",
				},
				{
					id: 115,
					country: "玻利维亚",
					area_code: 591,
					continent: "南美洲",
				},
				{
					id: 116,
					country: "圭亚那",
					area_code: 592,
					continent: "南美洲",
				},
				{
					id: 117,
					country: "厄瓜多尔",
					area_code: 593,
					continent: "南美洲",
				},
				{
					id: 118,
					country: "法属圭亚那",
					area_code: 594,
					continent: "南美洲",
				},
				{
					id: 119,
					country: "巴拉圭",
					area_code: 595,
					continent: "南美洲",
				},
				{
					id: 120,
					country: "马提尼克",
					area_code: 596,
					continent: "南美洲",
				},
				{
					id: 121,
					country: "苏里南",
					area_code: 597,
					continent: "南美洲",
				},
				{
					id: 122,
					country: "乌拉圭",
					area_code: 598,
					continent: "南美洲",
				},
				{
					id: 123,
					country: "埃及",
					area_code: 20,
					continent: "非洲",
				},
				{
					id: 124,
					country: "南非",
					area_code: 27,
					continent: "非洲",
				},
				{
					id: 125,
					country: "摩洛哥",
					area_code: 212,
					continent: "非洲",
				},
				{
					id: 126,
					country: "阿尔及利亚",
					area_code: 213,
					continent: "非洲",
				},
				{
					id: 127,
					country: "突尼斯",
					area_code: 216,
					continent: "非洲",
				},
				{
					id: 128,
					country: "利比亚",
					area_code: 218,
					continent: "非洲",
				},
				{
					id: 129,
					country: "冈比亚",
					area_code: 220,
					continent: "非洲",
				},
				{
					id: 130,
					country: "塞内加尔",
					area_code: 221,
					continent: "非洲",
				},
				{
					id: 131,
					country: "毛里塔尼亚",
					area_code: 222,
					continent: "非洲",
				},
				{
					id: 132,
					country: "马里",
					area_code: 223,
					continent: "非洲",
				},
				{
					id: 133,
					country: "几内亚",
					area_code: 224,
					continent: "非洲",
				},
				{
					id: 134,
					country: "科特迪瓦",
					area_code: 225,
					continent: "非洲",
				},
				{
					id: 135,
					country: "布基拉法索",
					area_code: 226,
					continent: "非洲",
				},
				{
					id: 136,
					country: "尼日尔",
					area_code: 227,
					continent: "非洲",
				},
				{
					id: 137,
					country: "多哥",
					area_code: 228,
					continent: "非洲",
				},
				{
					id: 138,
					country: "贝宁",
					area_code: 229,
					continent: "非洲",
				},
				{
					id: 139,
					country: "毛里求斯",
					area_code: 230,
					continent: "非洲",
				},
				{
					id: 140,
					country: "利比里亚",
					area_code: 231,
					continent: "非洲",
				},
				{
					id: 141,
					country: "塞拉利昂",
					area_code: 232,
					continent: "非洲",
				},
				{
					id: 142,
					country: "加纳",
					area_code: 233,
					continent: "非洲",
				},
				{
					id: 143,
					country: "尼日利亚",
					area_code: 234,
					continent: "非洲",
				},
				{
					id: 144,
					country: "乍得",
					area_code: 235,
					continent: "非洲",
				},
				{
					id: 145,
					country: "中非",
					area_code: 236,
					continent: "非洲",
				},
				{
					id: 146,
					country: "喀麦隆",
					area_code: 237,
					continent: "非洲",
				},
				{
					id: 147,
					country: "佛得角",
					area_code: 238,
					continent: "非洲",
				},
				{
					id: 148,
					country: "圣多美",
					area_code: 239,
					continent: "非洲",
				},
				{
					id: 149,
					country: "普林西比",
					area_code: 239,
					continent: "非洲",
				},
				{
					id: 150,
					country: "赤道几内亚",
					area_code: 240,
					continent: "非洲",
				},
				{
					id: 151,
					country: "加蓬",
					area_code: 241,
					continent: "非洲",
				},
				{
					id: 152,
					country: "刚果",
					area_code: 242,
					continent: "非洲",
				},
				{
					id: 153,
					country: "扎伊尔",
					area_code: 243,
					continent: "非洲",
				},
				{
					id: 154,
					country: "安哥拉",
					area_code: 244,
					continent: "非洲",
				},
				{
					id: 155,
					country: "几内亚比绍",
					area_code: 245,
					continent: "非洲",
				},
				{
					id: 156,
					country: "阿森松",
					area_code: 247,
					continent: "非洲",
				},
				{
					id: 157,
					country: "塞舌尔",
					area_code: 248,
					continent: "非洲",
				},
				{
					id: 158,
					country: "苏丹",
					area_code: 249,
					continent: "非洲",
				},
				{
					id: 159,
					country: "卢旺达",
					area_code: 250,
					continent: "非洲",
				},
				{
					id: 160,
					country: "埃塞俄比亚",
					area_code: 251,
					continent: "非洲",
				},
				{
					id: 161,
					country: "索马里",
					area_code: 252,
					continent: "非洲",
				},
				{
					id: 162,
					country: "吉布提",
					area_code: 253,
					continent: "非洲",
				},
				{
					id: 163,
					country: "肯尼亚",
					area_code: 254,
					continent: "非洲",
				},
				{
					id: 164,
					country: "坦桑尼亚",
					area_code: 255,
					continent: "非洲",
				},
				{
					id: 165,
					country: "乌干达",
					area_code: 256,
					continent: "非洲",
				},
				{
					id: 166,
					country: "布隆迪",
					area_code: 257,
					continent: "非洲",
				},
				{
					id: 167,
					country: "莫桑比克",
					area_code: 258,
					continent: "非洲",
				},
				{
					id: 168,
					country: "赞比亚",
					area_code: 260,
					continent: "非洲",
				},
				{
					id: 169,
					country: "马达加斯加",
					area_code: 261,
					continent: "非洲",
				},
				{
					id: 170,
					country: "留尼旺岛",
					area_code: 262,
					continent: "非洲",
				},
				{
					id: 171,
					country: "津巴布韦",
					area_code: 263,
					continent: "非洲",
				},
				{
					id: 172,
					country: "纳米比亚",
					area_code: 264,
					continent: "非洲",
				},
				{
					id: 173,
					country: "马拉维",
					area_code: 265,
					continent: "非洲",
				},
				{
					id: 174,
					country: "莱索托",
					area_code: 266,
					continent: "非洲",
				},
				{
					id: 175,
					country: "博茨瓦纳",
					area_code: 267,
					continent: "非洲",
				},
				{
					id: 176,
					country: "斯威士兰",
					area_code: 268,
					continent: "非洲",
				},
				{
					id: 177,
					country: "科摩罗",
					area_code: 269,
					continent: "非洲",
				},
				{
					id: 178,
					country: "圣赫勒拿",
					area_code: 290,
					continent: "非洲",
				},
				{
					id: 179,
					country: "厄立特里亚",
					area_code: 291,
					continent: "非洲",
				},
				{
					id: 180,
					country: "阿鲁巴岛",
					area_code: 297,
					continent: "非洲",
				},
				{
					id: 181,
					country: "法罗群岛",
					area_code: 298,
					continent: "非洲",
				},
				{
					id: 182,
					country: "摩纳哥",
					area_code: 377,
					continent: "非洲",
				},
				{
					id: 183,
					country: "澳大利亚",
					area_code: 61,
					continent: "大洋洲",
				},
				{
					id: 184,
					country: "新西兰",
					area_code: 64,
					continent: "大洋洲",
				},
				{
					id: 185,
					country: "关岛",
					area_code: 671,
					continent: "大洋洲",
				},
				{
					id: 186,
					country: "瑙鲁",
					area_code: 674,
					continent: "大洋洲",
				},
				{
					id: 187,
					country: "汤加",
					area_code: 676,
					continent: "大洋洲",
				},
				{
					id: 188,
					country: "所罗门群岛",
					area_code: 677,
					continent: "大洋洲",
				},
				{
					id: 189,
					country: "瓦努阿图",
					area_code: 678,
					continent: "大洋洲",
				},
				{
					id: 190,
					country: "斐济",
					area_code: 679,
					continent: "大洋洲",
				},
				{
					id: 191,
					country: "科克群岛",
					area_code: 682,
					continent: "大洋洲",
				},
				{
					id: 192,
					country: "纽埃岛",
					area_code: 683,
					continent: "大洋洲",
				},
				{
					id: 193,
					country: "东萨摩亚",
					area_code: 684,
					continent: "大洋洲",
				},
				{
					id: 194,
					country: "西萨摩亚",
					area_code: 685,
					continent: "大洋洲",
				},
				{
					id: 195,
					country: "基里巴斯",
					area_code: 686,
					continent: "大洋洲",
				},
				{
					id: 196,
					country: "图瓦卢",
					area_code: 688,
					continent: "大洋洲",
				},
				{
					id: 197,
					country: "科科斯岛",
					area_code: 619162,
					continent: "大洋洲",
				},
				{
					id: 198,
					country: "诺福克岛",
					area_code: 6723,
					continent: "大洋洲",
				},
				{
					id: 199,
					country: "圣诞岛",
					area_code: 619164,
					continent: "大洋洲",
				},
				{
					id: 200,
					country: "美国",
					area_code: 1,
					continent: "北美洲",
				},
				{
					id: 201,
					country: "加拿大",
					area_code: 1,
					continent: "北美洲",
				},
				{
					id: 202,
					country: "夏威夷",
					area_code: 1808,
					continent: "北美洲",
				},
				{
					id: 203,
					country: "阿拉斯加",
					area_code: 1907,
					continent: "北美洲",
				},
				{
					id: 204,
					country: "格陵兰岛",
					area_code: 299,
					continent: "北美洲",
				},
				{
					id: 205,
					country: "中途岛",
					area_code: 1808,
					continent: "北美洲",
				},
				{
					id: 206,
					country: "威克岛",
					area_code: 1808,
					continent: "北美洲",
				},
				{
					id: 207,
					country: "维尔京群岛",
					area_code: 1809,
					continent: "北美洲",
				},
				{
					id: 208,
					country: "波多黎各",
					area_code: 1809,
					continent: "北美洲",
				},
				{
					id: 209,
					country: "巴哈马",
					area_code: 1809,
					continent: "北美洲",
				},
				{
					id: 210,
					country: "安圭拉岛",
					area_code: 1809,
					continent: "北美洲",
				},
				{
					id: 211,
					country: "圣卢西亚",
					area_code: 1809,
					continent: "北美洲",
				},
				{
					id: 212,
					country: "巴巴多斯",
					area_code: 1809,
					continent: "北美洲",
				},
				{
					id: 213,
					country: "牙买加",
					area_code: 1876,
					continent: "北美洲",
				},
				{
					id: 214,
					country: "南极洲",
					area_code: 64672,
					continent: "南极洲",
				},
			],
		};
	},
	mounted() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
			this.btnText = "获取验证码";
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
			this.btnText = "Get Verification Code";
		} else {
			this.localLanguage = 1;
			this.btnText = "获取验证码";
		}
	},
	methods: {
		handleCommand(command) {
			this.loginForm.phone_code = this.areaList[command].area_code;
		},
		concatUs() {
			console.log("点击这里申诉");
		},
		changeLoginType(num) {
			this.loginType = num;
		},
		changeToLast() {
			// 回到上一步
			this.currentStep = this.currentStep - 1;
		},
		handleLogin() {
			console.log(
				"判断是第几步",
				this.currentStep,
				this.loginType,
				this.loginForm.password
			);

			if (this.currentStep == 1) {
				if (this.loginType == 1) {
					login({
						mobile: this.loginForm.username.trim(),
						password: this.loginForm.password,
						type: "1",
					})
						.then((response) => {
							if (response.code != 200) {
								this.$message.error(response.message);
							} else {
								setToken(response.data.token);
								this.userId = response.data.id;
								this.currentStep = 2;
							}
						})
						.catch((error) => {
							this.$message.error(response.message);
						});
				} else {
					login({
						mobile: this.loginForm.username.trim(),
						phone_code: this.loginForm.phone_code,
						code: this.loginForm.code,
						type: "2",
					})
						.then((response) => {
							if (response.code != 200) {
								this.$message.error(response.message);
							} else {
								setToken(response.data.token);
								this.userId = response.data.id;
								this.currentStep = 2;
							}
						})
						.catch((error) => {
							this.$message.error(response.message);
						});
				}
			} else if (this.currentStep == 2) {
				verifyEmailSms({
					email: this.loginForm.email,
					code: this.loginForm.emailCode,
				})
					.then((response) => {
						if (response.code != 200) {
							this.$message.error(response.message);
						} else {
							this.currentStep = 3;
						}
					})
					.catch((error) => {
						this.$message.error(error.message);
					});
			} else if (this.currentStep == 3) {
				editUserInfo({
					id: this.userId,
					email: this.loginForm.email,
				})
					.then((res) => {
						if (res.code == 200) {
							this.resetPassword();
						} else {
							this.$message.error(response.message);
						}
					})
					.catch(() => {});
			}
		},

		resetPassword() {
			resetPassword({
				new_password1: this.loginForm.new_password1,
				new_password2: this.loginForm.new_password2,
			})
				.then((response) => {
					// this.$parent.$parent.showStatus = 1;
					if (response.code != 200) {
						this.$message.error(response.message);
					} else {
						this.$parent.$parent.showStatus = 1;
					}
				})
				.catch(() => {});
		},

		toSendMailSms() {
			sendEmailSms({
				email: this.loginForm.email,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},

		tosendSms() {
			sendSms({
				phone: this.loginForm.username,
				phone_code: this.loginForm.phone_code,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					console.log(error);
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.up_step_wraper {
	padding: 0 0 44px 0;
	min-height: 460px;

	.top-part {
		color: #333;

		img {
			margin-right: 15px;
		}

		.main-title {
			font-size: 24px;
			font-weight: bold;
			line-height: 24px;
		}

		.sub-title {
			font-size: 16px;
			line-height: 16px;
			margin-top: 10px;
		}
	}
	.js-login__form {
		margin-top: 36px;
	}

	.icon-box {
		margin-top: 40px;
		padding: 0 32px;
	}

	.step-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
	}

	.step-line {
		width: 30px;
		height: 30px;

		span {
			width: 100%;
			height: 1px;
			background: #d8d8d8;
		}
	}

	.step-icon {
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 50%;
		text-align: center;
	}

	.step-text {
		font-size: 16px;
		line-height: 16px;
		margin-top: 10px;
	}

	.step-num {
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 50%;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
	}

	.active .step-num {
		background: #333;
	}

	.active .step-text {
		color: #333;
	}

	.next .step-num {
		background: #d8d8d8;
	}

	.next .step-text {
		color: #d8d8d8;
	}

	.done .step-text {
		color: #0dea60;
	}

	.step-icon,
	.done + .step-line span {
		background: #0dea60;
	}
	.loginBtn {
		color: #fff;
	}
	.loginBtn2,
	.lastStep {
		margin-top: 40px;
	}
	.lastStep {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: center;
		border-radius: 4px;
		border: 1px solid #000;
		color: #333;
		font-size: 16px;
		font-weight: 600;
		background: #fff;
	}
	.loginBtn3 {
		margin-top: 15px;
	}
}
</style>

<style>
.el-dropdown-menu {
	max-height: 200px; /* 设置最大高度为 200px */
	overflow-y: auto; /* 如果内容超出高度，显示滚动条 */
}
.login__phone_code {
	margin-left: 10px;
	font-size: 14px;
	display: flex;
	width: 90px;
	align-items: center;
	text-align: center;
	align-content: center;
	color: #333333;
}
</style>

<template>
	<div>
		<div class="mz">
			<div class="width_1200 margin_auto flex align_items_c"> </div>
		</div>
		<div class="headerg">
			<div class="firstHeader">
				<div
					class="margin_auto flex align_items_c justify_content_sb height_100"
				>
					<div class="header_l flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/logonew.png"
							alt=""
							@click="toHome()"
						/>
					</div>
					<div class="header_r flex align_items_c">
						<div
							class="js-func__app header_sousuo_img pointer"
							@click="
								$router.push({
									path: '/search',
								})
							"
						>
							<img
								src="https://res.metaera.hk/resources/assets/images/home/home_sousuo.png"
								alt="MetaEra中国版App下载"
								@click="gotoDownLoad"
							/>
						</div>
						<div class="js-func__app header_r_img pointer">
							<img
								src="https://res.metaera.hk/resources/assets/images/aboutus/download_phone.png"
								alt="MetaEra中国版App下载"
								@click="gotoDownLoad"
							/>
						</div>
					</div>
				</div>
				<div class="app_navList flex align_items_c">
					<div
						class="navItem pointer"
						v-for="(item, index) in navList"
						:key="index"
						@click="toPath(item, index)"
						:class="{ active: currentPath == item.router }"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { getToken } from "@/utils/auth";
export default {
	name: "",
	created() {
		this.token = getToken();
	},
	data() {
		return {
			token: 0,
			active: 0,
			showHeader: true,
			currentPath: "/",
			keyword: "",
			navList: [
				{
					name: "首页",
					router: "/",
				},
				{
					name: "快讯",
					router: "/home_kuaixun",
				},
				{
					name: "专栏",
					router: "/home_zl",
				},
				{
					name: "活动",
					router: "/events",
				},
				{
					name: "美国",
					router: "/usa",
				},
				{
					name: "香港",
					router: "/hk",
				},
			],
		};
	},
	created() {
		this.token = getToken();
	},
	methods: {
		download() {
			let ua = navigator.userAgent.toLowerCase();
			//ios终端
			let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.olead = true;
			}
			if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) || isiOS) {
				//判断是ios还是android
				window.location.href =
					"https://apps.apple.com/cn/app/meta-era/id6450677755"; // 跳AppStore下载地址
			} else if (/(Android)/i.test(navigator.userAgent)) {
				window.location.href =
					"https://res.metaera.hk/resources/MetaEra_1_2_6.apk"; //打开调整的地址
			} else {
				window.location.href =
					"https://res.metaera.hk/resources/MetaEra_1_2_6.apk"; //打开调整的地址
			}
			var that = this;
			setTimeout(function () {
				that.$router.push({
					path: "/download",
				});
			}, 1000);
		},
		gotoDownLoad() {
			this.$router.push({
				path: "/download",
			});
		},
		handleEnterKey() {
			this.$router.push({
				path: "/search",
			});
		},
		toHome() {
			this.$router.push("/");
			this.active = 0;
		},

		login() {
			this.$PromptTip({ dialogVisible: true, showStatus: 1 });
		},
		toPath(item, index) {
			this.keyword = "";
			this.$router.push({ path: item.router });
			this.active = index;
		},
		logOut() {
			store.dispatch("user/logout");
		},
	},
	watch: {
		"$route.name": function (n) {
			this.showHeader = n == "/";
			this.currentPath = n;
		},
	},
};
</script>

<style scoped>
/* 头部 */
.mz {
	width: 100%;
	height: 0px;
	background-color: #fff;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
}

.mz div {
	font-size: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #9e9fa7;
}

.headerg {
	width: 100%;
	height: 80px;
	background: #fff;
	box-shadow: 0px 3px 6px 1px #f7f7f7;
}

.header_l img {
	width: auto;
	height: 16px;
	margin-left: 10px;
}

.navItem {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #a0a0a9;
}

.search {
	width: auto;
	height: 24px;
	background: #ffffff;
	border-radius: 21px 21px 21px 21px;
	opacity: 1;
	border: 1px solid #dedee1;
	margin-right: 6px;
}

.searchBtn {
	width: 60px;
	height: 100%;
	background: linear-gradient(153deg, #8a11ff 0%, #176dff 100%);
	border-radius: 75px;
	opacity: 1;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #ffffff;
	justify-content: center;
}

.searchBtn span {
	font-size: 12px;
	color: #ffffff;
	padding-right: 8px;
}

.searchBtn img {
	width: 16px;
	height: 16px;
	padding-left: 8px;
}

.header_r_img {
	width: 30px;
	height: 30px;
	margin-right: 6px;
}

.header_r_img img {
	width: 30px;
	height: 30px;
}
.header_sousuo_img {
	width: 30px;
	height: 30px;
	background: #f5f5f5;
	border-radius: 15px 15px 15px 15px;
	opacity: 1;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}
.header_sousuo_img img {
	width: 16px;
	height: 16px;
}

.active {
	position: relative;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.firstHeader {
	padding-top: 10px;
	margin-bottom: 10px;
	padding-bottom: 6px;
	background: white;
}

.app_navList {
	margin-top: 6px;
	padding-bottom: 6px;
	display: flex;
	text-align: center;
	justify-content: center;
	background: #ffffff;
	box-shadow: 0px 3px 6px 1px #f7f7f7;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
}
.firstHeader .active::after {
	display: none;
	position: absolute;
	content: "";
	left: 50%;
	transform: translateX(-50%);
	bottom: -1px;
	width: 33px;
	height: 2px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
}
.app_navList {
	height: 40px;
}
</style>
<style scoped></style>

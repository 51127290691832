<template>
	<div v-if="version == 2" class="version2 flex direction_column">
		<div class="active">{{
			showType && showType == 1 ? "修改密码" : "忘记密码"
		}}</div>
		<div class="js-login">
			<div class="js-login__form">
				<p class="js-login__phone flex align_items_c">
					<img
						src="https://res.metaera.hk/resources/assets/images/my/email_tu.png"
						class="pointer"
					/>
					<input
						type="text"
						placeholder="请输入邮箱"
						v-model="loginForm.email"
					/>
				</p>
				<div class="margin_top_20 flex login_psd_box">
					<p class="js-login__psd flex-shrink">
						<img
							src="https://res.metaera.hk/resources/assets/images/my/yzm_tu.svg"
							class="pointer"
						/>
						<input
							placeholder="请输入验证码"
							type="text"
							v-model="loginForm.code"
						/>
					</p>
					<p class="login_psd_btn flex pointer">
						<span class="login_psd_text" @click="tosendSms" v-show="show">{{
							btnText
						}}</span>
						<span class="login_psd_count" v-show="!show">{{ count }} s</span>
					</p>
				</div>
				<p class="js-login__psd margin_top_20">
					<img
						src="https://res.metaera.hk/resources/assets/images/my/pass_tu.png"
						class="pointer"
					/>
					<input
						placeholder="请输入新密码"
						type="password"
						v-model="loginForm.new_password1"
					/>
				</p>
				<p class="js-login__psd margin_top_20">
					<img
						src="https://res.metaera.hk/resources/assets/images/my/pass_tu.png"
						class="pointer"
					/>
					<input
						placeholder="请输入新密码"
						type="password"
						v-model="loginForm.new_password2"
					/>
				</p>
				<button
					class="loginBtn flex align_items_c justify_content_c pointer"
					@click="resetPass"
					:disabled="
						loginForm.email == '' ||
						loginForm.code == '' ||
						loginForm.new_password1 == '' ||
						loginForm.new_password2 == ''
					"
				>
					修改密码
				</button>
			</div>
			<div class="return_btn flex justify_content_c margin_top_20">
				<p class="pointer" @click="exit()"
					>{{ showType && showType == 1 ? "退出" : "返回登录"
					}}<img
						src="https://res.metaera.hk/resources/assets/images/my/arrow_tu.svg"
				/></p>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import { sendSms, sendEmailSms, forgotPassword } from "@/api/home";
import login__areaCode from "./login__areaCode.vue";
import "./login_common.css";
export default {
	name: "",
	props: ["version", "showType"],
	components: { login__areaCode },
	data() {
		return {
			loginForm: {
				mobile: "",
				code: "",
				new_password1: "",
				new_password2: "",
				phone_code: "86",
				type: "2",
				email: "",
			},
			passType: "password",
			showtimezone: false,
			// 验证码
			show: true,
			timer: null,
			count: "",
			localLanguage: 0,
			btnText: "获取验证码",
		};
	},
	mounted() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
			this.btnText = "获取验证码";
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
			this.btnText = "Get Verification Code";
		} else {
			this.localLanguage = 1;
			this.btnText = "获取验证码";
		}
	},
	methods: {
		exit() {
			this.$parent.$parent.showStatus = 1;
			this.$emit("exit", 0);
		},
		getphone_code(val) {
			this.loginForm.phone_code = val;
			this.showtimezone = false;
		},
		tosendSms() {
			sendEmailSms({
				email: this.loginForm.email,
			})
				.then((res) => {
					if (res.code == 200) {
						this.show = false;
						const TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.show = false;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		resetPass() {
			forgotPassword(this.loginForm)
				.then((res) => {
					if (res.code == 200) {
						this.$parent.$parent.showStatus = 1;
						this.$emit("exit", 1);
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.loginTab {
	margin-top: 14px;
}

.loginTab div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.loginTab div:nth-child(2) {
	margin-left: 47px;
}

.active {
	position: relative;
	font-weight: bold;
}
.loginBtn {
	width: 335px;
	height: 50px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	margin-top: 23px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	border: none;
	outline: none;
}

.loginBtn[disabled] {
	background: #8cb3ff;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0px;
}

@import url("../../assets/log.css");
</style>
